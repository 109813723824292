<template>
  <v-container fluid class="mb-10">
    <v-row class="ml-7" style="width: 96%">
      <v-col cols="12">
        <v-card class="pa-5">
          <h3>
            Edit Image :
            <span class="ml-2 font-weight-regular">{{ product_name }}</span>
          </h3>
          <v-row>
            <v-col cols="12">
              <v-file-input
                color="deep-purple accent-4"
                counter
                dense
                label="Upload image"
                multiple
                placeholder="Select your images"
                prepend-icon="mdi-camera"
                outlined
                show-size
                accept="image/png, image/jpeg, image/jpg"
                v-model="images"
                @click:clear="deleteAllImage"
                @change="Preview_image"
              >
                <template v-slot:selection="{ index, text }">
                  <v-chip
                    v-if="index < 6"
                    color="deep-purple accent-4"
                    dark
                    label
                    small
                    >{{ text }}</v-chip
                  >

                  <span
                    v-else-if="index === 2"
                    class="overline grey--text text--darken-3 mx-2"
                    >+{{ images.length - 2 }} File(s)</span
                  >
                </template>
              </v-file-input>
            </v-col>
          </v-row>
          <v-row>
            <v-container v-if="addItem.image.length > 0">
              <p>New Images :</p>
              <v-col class="d-flex flex-row flex-wrap">
                <v-tooltip
                  top
                  v-for="(image, index) in addItem.image"
                  :key="index"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-img
                      :src="image.image"
                      max-height="200"
                      max-width="200"
                      class="mr-2 mb-4 thumbnailImage"
                      @click="cropModal(image, index)"
                      v-bind="attrs"
                      v-on="on"
                      :style="[
                        image.excede
                          ? { border: '4px solid red' }
                          : { border: '4px solid green' },
                      ]"
                    >
                      <v-icon
                        color="red"
                        class="float-right"
                        @click.stop="deleteImage(index)"
                        >mdi-close-thick</v-icon
                      >
                    </v-img>
                  </template>
                  <span v-if="image.excede">Crop your image</span>
                  <span v-else>Cropping is not required</span>
                </v-tooltip>
              </v-col>
            </v-container>
          </v-row>

          <v-row>
            <v-container v-if="fetchedImages.length > 0">
              <p>Old Images :</p>
              <v-col class="d-flex flex-row flex-wrap">
                <v-tooltip
                  top
                  v-for="(image, index) in fetchedImages"
                  :key="index"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-img
                      :src="image.image_url"
                      max-height="200"
                      max-width="200"
                      class="mr-2 mb-4 thumbnailImage"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon
                        color="red"
                        class="float-right"
                        @click="DeleteOldImage(image)"
                        >mdi-close-thick</v-icon
                      >
                    </v-img>
                  </template>
                  <span>Delete Image</span>
                </v-tooltip>
              </v-col>
            </v-container>
          </v-row>

          <v-row justify="end">
            <v-btn
              @click="save"
              :color="
                this.addItem.image.length + this.fetchedImages.length > 6
                  ? 'red'
                  : 'primary'
              "
              >Save</v-btn
            >
          </v-row>
        </v-card>
      </v-col>
    </v-row>

    <v-dialog v-model="showCrop" max-width="1050px" class="ma-0">
      <v-card>
        <v-row class="ma-0">
          <v-col>
            <div class="cut">
              <vue-cropper
                ref="cropper"
                :img="cropBlob.image"
                :output-size="option.size"
                :output-type="option.outputType"
                :info="true"
                :full="option.full"
                :fixed="fixed"
                :fixed-number="fixedNumber"
                :can-move="option.canMove"
                :can-move-box="option.canMoveBox"
                :fixed-box="option.fixedBox"
                :original="option.original"
                :auto-crop="option.autoCrop"
                :auto-crop-width="option.autoCropWidth"
                :auto-crop-height="option.autoCropHeight"
                :center-box="option.centerBox"
                :high="option.high"
                mode="cover"
                :max-img-size="option.max"
                @real-time="realTime"
              ></vue-cropper>
            </div>
          </v-col>
          <v-col class="text-center">
            <div
              class="show-preview"
              :style="{
                width: previews.w + 'px',
                height: previews.h + 'px',
                overflow: 'hidden',
                border: '1px solid green',
                'margin-bottom': '10px',
              }"
            >
              <div :style="previews.div">
                <img :src="previews.url" :style="previews.img" />
              </div>
            </div>

            <div class="d-flex flex-row justify-space-between mr-6">
              <v-btn @click="showCrop = false" color="red" tile elevation="0"
                >Go back</v-btn
              >

              <v-btn
                @click="getResult"
                color="success"
                tile
                elevation="0"
                outlined
                >Crop & Confirm</v-btn
              >
            </div>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>

    <v-snackbar v-model="snackbar" centered absolute>
      {{ text }}

      <template v-slot:action="{ attrs }">
        <v-btn color="pink" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>


<script>
import { VueCropper } from "vue-cropper";

export default {
  data() {
    return {
      product_name: "",

      images: [],
      fetchedImages: [],
      old_images: [],

      cropBlob: {},

      showCrop: false,

      crap: false,
      previews: {},
      option: {
        img: "",
        size: 1,
        full: false,
        outputType: "image/png",
        canMove: true,
        fixedBox: true,
        original: false,
        canMoveBox: true,
        autoCrop: true,
        autoCropWidth: 475,
        autoCropHeight: 475,
        centerBox: false,
        high: true,
        max: 99999,
      },
      show: true,
      fixed: true,
      fixedNumber: [1, 1],

      addItem: {
        image: [],
      },

      text: "",
      snackbar: false,
    };
  },

  components: {
    VueCropper,
  },

  methods: {
    initialize() {
      axios
        .get(`productdetails/detail/${this.$route.query.spm}/`)
        .then((response) => {
          //  console.log("Fetched ", response.data.data); 
          this.fetchedImages = response.data.data.imagez;
          this.product_name = response.data.data.title;

          //  {{ this.$route.params }}
        });
    },

    Preview_image() {
      this.images.forEach((image) => {
        if (/\.(jpg|png|jpeg)$/i.test(image.name) == false) {
          alert("We accept image file ends with jpg/jpeg/png only !");
          this.images.length = 0;
          this.addItem.image.length = 0;
          return;
        }

        let previewObject = {};

        const reader = new FileReader();
        reader.onload = (e) => {
          previewObject.image = e.target.result;
        };
        reader.readAsDataURL(image);

        let BlobObject = URL.createObjectURL(image);

        let img = new Image();
        img.src = BlobObject;

        img.onload = () => {
          let w = img.width;
          let h = img.height;

          let excedeFlag = false;

          if (w > 475 || h > 475) {
            excedeFlag = true;
          }

          previewObject.excede = excedeFlag;
          previewObject.name = image.name;
          previewObject.type = image.type;
          this.addItem.image.push(previewObject);
        };
      });
    },

    cropModal(image, index) {
      this.cropBlob = image;
      this.cropBlob.index = index;
      this.showCrop = true;
    },

    realTime(data) {
      this.previews = data;
    },

    getResult() {
      this.$refs.cropper.getCropData((data) => {
        this.addItem.image[this.cropBlob.index].image = data;
        this.addItem.image[this.cropBlob.index].excede = false;
        this.showCrop = false;
      });
    },

    deleteAllImage() {
      this.images.length = 0;
      this.addItem.image.length = 0;
    },

    deleteImage(index) {
      this.addItem.image.splice(index, 1);
      this.images.splice(index, 1);
    },

    DeleteOldImage(image) {
      this.fetchedImages = this.fetchedImages.filter(
        (item) => item.id != image.id
      );

      this.old_images.push(image);
    },

    save() {
      //Upload a product

      let total = this.addItem.image.length + this.fetchedImages.length;

      if (total > 6) {
        this.text = "You can upload maximum 6 images";
        this.snackbar = true;
      } else {
        let images = [];

        for (let index = 0; index < this.addItem.image.length; index++) {
          images[index] = this.addItem.image[index].image;
        }

        let EditObject = {
          images: images,
          oldImage: this.old_images,
        };

        // this.$route.params.id

        // console.log("Edit ", EditObject);

        axios
          .post(`product/edit_images/${this.$route.query.spm}/`, EditObject)
          .then((response) => {
            if (response.data.success) {
               this.text = response.data.message;
             // this.text = "Updated successfully";
              this.snackbar = true;
              //
              setTimeout(() => {
                this.$router.go(this.$router.currentRoute);
              }, 1000);
            } else {
               this.text = response.data.message;
             // this.text = "Something went wrong !";
              this.snackbar = true;
            }
          })
          .catch((err) => {
            this.text = "Something went wrong !";
            this.snackbar = true;
          });
      }
    },
  },

  mounted() {
    this.initialize();
  },
};
</script>

<style >
.cut {
  width: 500px;
  height: 560px;
  margin: 30px auto;
  margin-top: 0px;
}

.c-item {
  max-width: 800px;
  margin: 10px auto;
  margin-top: 20px;
}

.content {
  margin: auto;
  max-width: 1200px;
  margin-bottom: 100px;
}

.cropper {
  height: 600px;
  background: #ddd;
}

.cropper-view-box {
  outline: 1px solid red !important;
}

.thumbnailImage:hover {
  cursor: pointer;
}

.v-sheet--offset {
  top: -10px;
  position: relative;
}
</style>